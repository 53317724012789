/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        jQuery('.nav-primary-search-toggle').on('click', function(){
            jQuery('.nav-primary-search-field').toggleClass('active');
        });


        $(window).on('scroll', function(){
          pos = $(window).scrollTop();

          if(pos > 50) {
            $('[data-contact-buttons]').addClass('active');
          } else {
            $('[data-contact-buttons]').removeClass('active');
          }
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // SLICK SLIDER FOR QUOTES
        $('.quote-slides').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          autoplay: true
        });

        var iframe = document.querySelector('iframe');
        // var player = new Vimeo.Player(iframe);
        // jQuery('[data-play-button]').on('click', function(){
        //   jQuery('.bgfl-video-block-inner').hide();
        //   player.play();
        // });

        // PIN CLICK HANDLER
        $('.bgfl-home-map-pin').on('click', function(){
          var destination = jQuery(this).attr('data-port');
          $('.map-overlay').removeClass('active');
          $('.map-overlay[data-overlay-destination="'+destination+'"]').addClass('active');
        });

        $('.close-map-overlay').on('click', function(){
          $('.map-overlay').removeClass('active');
        });

        // COUNTER ANIMATION BEGIN
        var mobileActivated = false;

        var inviewMobile = new Waypoint.Inview({
          element: $('#count-1-mobile')[0],
          entered: function(direction) {
            if (!mobileActivated) {
              mobileActivated = true;
              // console.log('mobile activated');

              var countNum1 = $('#count-1-mobile').data('count');
              var countNum2 = $('#count-2-mobile').data('count');
              var countNum3 = $('#count-3-mobile').data('count');

              var options = { useEasing : false, useGrouping : true, separator : '.', decimal : ',', prefix : '', suffix : '' };

              var count1 = new CountUp("count-1-mobile", 0, countNum1, 0, 1.5, options);
              count1.start();

              var count2 = new CountUp("count-2-mobile", 0, countNum2, 0, 1.5, options);
              count2.start();

              var count3 = new CountUp("count-3-mobile", 0, countNum3, 0, 1.5, options);
              count3.start();
            }
          }
        });

        var desktopActivated = false;

        var inviewDesktop = new Waypoint.Inview({
          element: $('#count-1-desktop')[0],
          entered: function(direction) {
            if (!desktopActivated) {
              desktopActivated = true;
              // console.log('desktop activated');

              var countNum1 = $('#count-1-desktop').data('count');
              var countNum2 = $('#count-2-desktop').data('count');
              var countNum3 = $('#count-3-desktop').data('count');

              var options = { useEasing : false, useGrouping : true, separator : '.', decimal : ',', prefix : '', suffix : '' };

              var count1 = new CountUp("count-1-desktop", 0, countNum1, 0, 1.5, options);
              count1.start();

              var count2 = new CountUp("count-2-desktop", 0, countNum2, 0, 1.5, options);
              count2.start();

              var count3 = new CountUp("count-3-desktop", 0, countNum3, 0, 1.5, options);
              count3.start();
            }
          }
        });
        // COUNTER ANIMATION END



      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // BG Freightline
    'bg_freight_line': {
      init: function() {
        // COUNTER ANIMATION
        var activated = false;

        var inviewDesktop = new Waypoint.Inview({
          element: $('#bgfl-count-1')[0],
          entered: function(direction) {
            if (!activated) {
              activated = true;
              // console.log('desktop activated');

              var countNum1 = $('#bgfl-count-1').data('count');
              var countNum2 = $('#bgfl-count-2').data('count');
              var countNum3 = $('#bgfl-count-3').data('count');

              var options = { useEasing : false, useGrouping : true, separator : '.', decimal : ',', prefix : '', suffix : '' };

              var count1 = new CountUp("bgfl-count-1", 0, countNum1, 0, 1.5, options);
              count1.start();

              var count2 = new CountUp("bgfl-count-2", 0, countNum2, 0, 1.5, options);
              count2.start();

              var count3 = new CountUp("bgfl-count-3", 0, countNum3, 0, 1.5, options);
              count3.start();
            }
          }
        });
        $('.team-slides').slick({
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
          dots: false
        });
        // COUNTER ANIMATION END
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

    // Ports
    'ports': {
      init: function() {
        // PIN CLICK HANDLER
        $('.bgfl-home-map-pin').on('click', function(){
          var destination = jQuery(this).attr('data-port');
          $('.map-overlay').removeClass('active');
          $('.map-overlay[data-overlay-destination="'+destination+'"]').addClass('active');
        });

        $('.close-map-overlay').on('click', function(){
          $('.map-overlay').removeClass('active');
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'sailing_schedule': {
      init: function() {
        $('.bgfl-home-map-pin').on('click', function(){
          var destination = jQuery(this).attr('data-port');
          $('.map-overlay').removeClass('active');
          $('.map-overlay[data-overlay-destination="'+destination+'"]').addClass('active');
        });

        $('.close-map-overlay').on('click', function(){
          $('.map-overlay').removeClass('active');
        });
      
      },
      finalize: function() {

      }
    },

    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'at_your_service': {
      init: function() {
        // SLICK SLIDER FOR TEAM
        $('.team-slides').slick({
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
          dots: false
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
